<template>
  <div class="person-layout">
    <div class="layout-left">
      <div class="menu">
        <div class="menu-title">
          <i></i>Member Center
        </div>
        <div class="menu-list">
          <div class="item-menu-title">Order</div>
          <div class="item-menu" :class="{'select': select == item.url}" v-for="(item, index) in menus" :key="index" @click="goDetail(item.url)">
            <i :style="{'background-image':'url(' + item.icon + ')'}"></i>
            {{ item.label }}
          </div>
          <div class="line"></div>
          <div class="item-menu-title">My Account</div>
          <div class="item-menu" :class="{'select': select == item.url}" v-for="(item, index) in menus2" :key="'2' + index"
            @click="goDetail(item.url)">
            <i :style="{'background-image':'url(' + item.icon + ')'}"></i>
            {{ item.label }}
          </div>
          <div class="line"></div>
          <div class="item-menu-title">System</div>
          <div class="item-menu" :class="{'select': select == item.url}" v-for="(item, index) in menus3" :key="'3' + index"
            @click="goDetail(item.url)">
            <i :style="{'background-image':'url(' + item.icon + ')'}"></i>
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
    <div class="layout-right">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        menus: [{
          label: 'Shopping Cart',
          url: '/person/carts',
          icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAgZJREFUWEfFV0FuwjAQ3CV9ROgFeEnhD0TiVvoAIvUFwAuQwgPgWCn8oflJ00vJI2q22kCQ49gxIQ7NEdne8e7MeED45w+5vj/bDGUc2cd7+ihc+BxEOwKYKwVTInzLDoukayDYDyIyFEmOcTh5BIAvACiN4FI0PcbhqHMAPP+e8JZFIXkcxzjMOdLlVykgj4QIV4D03QkATyRMdh2ATwAYd1K0fGhO9AoAf7pdItLqAQCAALQAxojEXcg/XuQMDOFAvhx5YlTtwGwzROGxMs4APDFyZUxydxFg/xOH1Q5wUYWIE1eG1A+iq+S5s1kc7rUy6wfRlYishOywWLcdgx9EcwTYFecUEtcCUIjoxBFlyy/az2D0AMponTiiaaymDpSUAABNXkfdWrb6q93LDqsHoCih7fzl/QX5it+MXi8T0REAtt2EpSefdxMAJs3JE62UYPISI4AulKDrZB0AmYhOlNAMgHsiamNebeAw5MW7OSkbkFUFvCBPy79Pr4jE+UAX25qA4Q6s1Xflpsh1ATJs+ijxPttLagWgqIFvsbc9Tv50W84UNQ9aLQD1Bbv02/qfQRf1VQe8iQMmEhKhMSOot5cK5QFEJc09HahNSTx3OVG1A3A+jEOEnJKt+UATbFPyxERHSDsJWYrCGwPhgG9jI2BxY+ZPD+DlRJjW7bECaCL0e9b+AbeCGO3MAPWlAAAAAElFTkSuQmCC'
        }, {
          label: 'My Orders',
          url: '/person/orderList',
          icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAXBJREFUWEftV8FNw0AQnMUuIuFD6AR6wBI/kgLiFkwqQDIFhL/TQ9wJfuEUYWvRBhM554u1d45kGXG/KLPZ2dm9vQlh5EMj58d0CMyidElAolGMgPwri1dKrB02e3pPiPhBvmWmDRFL8uNnzSmzmOZRum/i88NuvbHFWVtwG6VbBpatgJyDeoUqXGiSI6wKVOELEb/+4gn4sKliJTCP0k8AZrJCpNUQ4B+lOvFlFt+b8S4ENLl7MdKWaRKQ/vmUb8wRvBS4NDwaQs1NOg2iFwEABQPWK9RLgumOiOUmnYbRl4CmWBVmsgTyMosfVSW2QLPntwXVwba9Pb0UGDKE5kb1ItAUVcgwOqogw3e2DYcQcMxth3sT+F9EzKQyF52HxvAQ3i24ygAAfm/BtZLL7/gqkIslO7ocx/OnFpHKilkEOjOxvi1wFP4yXE3A4ooHk3ByxfKS3dRBYlqqASwK2SWH3brTyun8NRtQfW/o6Ap8A/jvwiE30hMfAAAAAElFTkSuQmCC'
        }, {
          label: 'Supplement',
          url: '/person/supplement',
          icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAA0VJREFUWEfFl81qE1EUx8/JVPEFKkkX0qK4dO/K9BlMqjtbUeymUxV021bcuBIniChIWxAUJ0/gxvQZXAmCWUiSleJOMMmRc+ee8cyd71ZwICRk7sf//M7HPRfhPz9YZ//m9afLMF24gUhtO4+/h/xBgOGckH8fTfpbg6rrVhLQ7PR2EGkdAJYrLjwkwoNJf2uvbHyhgGan10ak/YyNY6t5A4qEyUfvyUI2iojkCmh2g3UE4M3lMVbBwvRw8u4eC0g9ykWaViGNTAFL3WCfAHgR8xDhbhWcMl4J2S1bIyXA+lsmliIs8jELwZn3UVyYZUhCgPU5T+BnSN5sNQ93WXBpGkpEyqCEgFY34M1NihHhap10qkDiqxg2Dv0VGR8L0EHnorIxkUhBIhzouFCpGgftOPRXYxIqqAlgYxL6B/wuFtDqBqzQbDIO/QQZK4DJiIgBARzKIjzHGrBjxwy4MI1CfyPPFULBbGSDxSDS6lysIjIvK+x7NiBGrNfQAS4ujgRoPN5spSjPJaBcEbJ4UewkDLWpbQTovHfxuxQSVlixkj1V6oVy9YBjxAhw/yxLMSXCpKqlkoter6cyLS0AAQ504BQJ0UFbJ20V7SHHyrEImLiJDipTtKqgF2PyBEgBMlgKi0qn1wYkk45yWDG5OcAREA7LipeQE9pCQAQYLHXQO2OrzCcr3rg7SsOo4TAHUJ0SHNcFVdnKArjVDYwAqTfpQlTj6FWFqfK54c7RpbiyG5iYwWipmRjgfjCnWWmtBbdxjhdH/a0HLqHMw6goHdUZn6JNAHv6fHDLPBBsjvv+Sz3xr4CoeeAWzHS6Zb1cma/l/dlrwXlvDl8MMSJ/1N/uZQpwc/ukDcli9/mFU/DbmwKc86DxwWxK8Bhx/oZ/jsK7n202JG1JtWTH6IpaV59tQgNfFFOi1+Nw+1ZmU5rRF1bq8WXD5lrvCRI9LHHTp3HoX8pvy1VtsAtFbXnFm8/SWnCTZnSaEBYR8ZHBPaf3BGDK96/Gmbc/wjs/iy8mTlerLMq6mGQGb60gzEJmevyZ10aAG9KwFqF1D6YTC9CbyYWjgbSsrmM8JL6cZl1gWp3gFSBc9mB65Vt4/3tuGlbN7X857g8FFz8/fpcSHQAAAABJRU5ErkJggg=='
        }, {
          label: 'Order Notice',
          url: '/person/notice',
          icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAWBJREFUWEftlt9NwzAQxr/DDJHylG7S7NBIvNEM0EhMUJgAKR2geURKd6CbEF5ohsA6ZCktUeTYTpwSIepX23e/++z7Q5h40cT+8XcBgvuX8EaKDQMhC5lUr4/lEDUHKzCLszcAi9rp4Vik0W8DcMNheSzS+cUBlOz4un0g4hWAsOWwZKa82q+f+4A4P0Gw3C6IWMluWwokqfbrg+2g2ncC6OH85NMZwglgFmfN93YJTJ1x+hdWgLs42zGg3rz3IiD/LNLEdNEKMDD681PYssMIoH49SfHeO/TGBRZybipSZoA4WxGw8wJgikwZcXkAIKmKNO8K4gpwVeAfKMD0ZOqQnQqMUYTq1CtZyKirGGkBRnT+0x07ILQArXHLpxA272q7o16B5XYzltezHeIPXUW0dsPRQVoGrQD1HNie/5y5bKOZEcBnGDkR8tA0VAaCqduxs84eB61/wMO209XJAb4Bn5KqIQFzydsAAAAASUVORK5CYII='
        },],
        menus2: [{
          label: 'Payment Record',
          url: '/person/paymentRecord',
          icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAYBJREFUWEftl19Og0AQxmcE70B9sd7Cx3qHkvRNOUBJPEHLCUzoAYpvJvQOpScRk8ZyCGDMbLoG20p2y7r40H2D7J8f33wzOyD0PLDn8+FXAG+8GBmFc8u8eHvOD/c8CeCNFzNEmhsFAMh2afigBUCERwvOgUKkGa/TBtiloRGPDPx4fQHorMBeRuXMIICgSMOEDzYSAs+Pn4DwVsOEm2I1zYwBaBx8NNWIAr0DKIUA6UPGvQlsRAFVE5JT3R2WXCMAvYfgAvDvFbjx42UNsJFZIJ4J82I1jayYUPYQXIK5YnI/QYRzmwAjRBLX7n7kRBhwObaiwMCP3wFg+AMAIOKQWAGQPSSrgABJTfgqYNwyx8pZdr6O27KgrUqyJxDg8U8BvMnLEEpXyI9I/LUc/4ifrXlAKsReQIDsMw2Dxjt7PSEDEGHC6dcZgG823QpI5dW9c03bmmgr155tQt3D2+ZzZjTD8g13apEwVeUoN6BKoE6VKf+aKW1oaJKRP58uLF9FdVwwMIH0hwAAAABJRU5ErkJggg=='
        }, {
          label: 'Address',
          url: '/person/address',
          icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAoZJREFUWEfFV0tu20AMJaMewu6mzklq38ECukt9gAjoCZycoIB8gDi7Asod6pyk6qb2Iaqw4GTockbzszYWYBiJJfLx8fFxhHDlC6+cHy4GMPvyfQF/P9wxcERamm+A/g3gFQj708v94ZKiigFwYhyqJwAwSWMXAuzfquHx9ONbXwKkCMCsbr8iACeXqyfCvfkD6fcNwGcCWChw5vfTy/1jDkQWwGy9WyLSTxuIA29iNFuW+F4GAwSwOXXNO9DIlQRgA/6yfd7/6ZpNriLRCCI9AEBP1bBKtSMJYF63XA33/HDsmpVObhOZSn1GPL2MntVxogB09US40klm693WVuhoQvc89XwZACs8VrWm3hMkK50/ZjKI8EGDEAb9AooAfKzbJwJg9TsA5nVLviYUI/2xa24lgfzfB1YEYF63LL6FVrKeiGPXOO0TYLpaxVZUB1ENKPrOtKq+OpVyRWcA1XArqhcAPotFDMToE2YkKIO6Gaott4v1oFsgbZzUAkWf31dtTI4t+GI7s5IwpNwYGlfzHS2wF0YOGROmb2RpJ/y/A4IWzEBCLqfFmrPjEiuWDZi1Va5OG1DIQS9iQAWUBTNSvx9Q2Xf2XusnufXiVpUaKZWcXdGx70nb0Fk+ai2HxkpGzlpyUfJiBs7Wqg4mGoReTqWVS8z4GK53W7tqnVONHi8+nJgq7IElxAzfH4qTBJCbYf27nH5iis+Z0YgBZ49HHCxgRNFlE3PUKAOpU9DoRDRUSyD8lDp8arChCXIYcByscIzyQwyg4o4c1QcgR60DATyXBB/dQxh8H0AkdtSFL9QoA5OSFzzk74axCN9n3bx6TbjMKTl2hV5Wsi8mE0Bc9MjVAfwDdpHgMOk7f6EAAAAASUVORK5CYII='
        }, {
          label: 'My Coupons',
          url: '/person/coupons',
          icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAVFJREFUWEftl0uOgkAQhqvCJXCnN5E7SOJSDyDJnGD0BCZ4gHF2k+AdhpvIDi7B1KQUTNMPnEn64YLeEUjVV3+9GoTABwP7h9cDiNfHuWtVmq+3qvfxUCBenZaI9AEArgFKIjw0l13JEA+AWZpfPTjvAy/rIktkAHItvWi/LrJb8KICE0BwBXwWYVUX2WJQA/Hq9I5Iex+FSIT75rI7DAD4oYPY6NqRCJO+d59Bxmm+QQCeKcqR7Sij2DSQKGoX4gQbg+hsfEvfVES4lYPQ7gIX6RBlF8FMADyW5QieKT/63qSgHmB9nGMbcVdYO/8DcNARf04Br2NsI5Z/sBUtdEFFUZvIhTxIgaF6b2mw0AV3OwDbpsjO6n1gpHetFUJnSDuIZmkefBdMAJMC3PtL2xVvsKe5D9wHEK9QpxAIcP4h/FSu5Z4iV9y83q+ZbyWCK/ALaVO3IbfQKg0AAAAASUVORK5CYII='
        }, {
          label: 'My Profile',
          url: '/person/profile',
          icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAllJREFUWEfFV11OwkAQngEOAb4IJ1HuYBPflAPYxBOoJzCpBxDfTOodxJNYX4RDgGMGdsl0u+3MosYmvCxl55u/7/tA+OcH/zk+JAEYnt+PYT24QKRTAOBPhQCLL4C3VZnPD0nGBIAD46b/6IK2xamIcLZ6uVqkAFEBuODvwaUVZ+/OuBL+YRDz1cvVnRWECmCUFa8i80aWoi23LmhF/c109XztAXZi6QQwPHu4QSR/8WJZ5tO224ZZcYkA3CZ+qmWZTyxV6AQwygou/Zgvov5momUlq0WEU8s8aACIgyPA/LPMZ1pGw7OHU0TilsGPAciSEsDMsmZyYInw1jKMrRUIsjFdxpmPsiKpau0Adrvv169zAH1rDqmaNgN+BU2rVRtCw9C6+WofrWC1UtbQVDEdQJOCG5WIEJF5A1QA/IKbbG7Flg880bAI0e5MUnFScBMAD8KpoGfFWN/+RoxkJJ6JHsCJyHwnx4SVZedjqFUx0tgv9j1zCCBtW6YRWBIAP3DboEgfbZcfZcUjAVzya8sy74xhAhAbxFAfuD0MyDEoq+JOxBwlb8FzRQKZVgEEfmBfcQlA8AV7ALkt+61x5w1+sDKhv2hBhGy53qTUev4P5iEKJhS2VgARJ9RqtULb5qU49JIxWY8COMTdCPNSK7N0VTGPEAVQc0JGZyN+U7NjciNMAGT2VickAXuqZnfcQxr7dRTnNeveqEANsVFS5W804gp5oQFAZqORyN6IuH9MMFg/RTSjIoA7IDyOkVcMQJKlimX860yolTX83osWn2tuWmXC1OCp738Dke6HMNCNRxUAAAAASUVORK5CYII='
        }],
        menus3: [{
          label: 'Letter',
          url: '/person/letter',
          icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAfdJREFUWEftl0FuwjAQRWcIhwjdNNwE7gASO+AAIPUEkBMghQMAu0rhDqUnId0UDkGY6kc2CmlikhjULrCExML2f/6e8UyY/niwSd/tLTsNlmFdxjPR5zGcrk3rjQCtfiB1xfU6EZ4ftxO/aJ+HA0BYhLvH7WSXB1EWICIi/KqOzl0AYCOxfFVVZ6LVXQCqCmfn3+MKrBgqASD1tBqzfOA/ExlTKY9OiDxSv5sAEGUW3BcW/Rp1AJK7JxrlbJcEtDjx+Pj+FrE7WHgcO3s1cccq2tUJLm7Y3EH6AEKEPZODihO3+aUfrECatUm5klyB7TiE06t0d/vBCBmC7GL12kWHcNpOCz0SADqtfpC4ngDAou9wOs6e1O0tZw2W3Lgo60pRPdDO5wJAGAKmN7wsAGKMTs0hDpI+pBlALWIWxMaamqcNIrasKOZpYb1H9jBGAC2U2qSD7DgLb4qKSnpNI3ZmiHbAF7lYCuAKJHYAgauJRNjPgqTeEuS/f6sPqASQA4ImxYMQCb/WuapaAFdpOlh4sPosHNWJEWuAKgGZN/cJ8HTgXzmwZ6JdXjGyjXTT+rQDKIsemoOq770NoG4DGJWPWeYoyWcn9h8NoZ5sPOmoFd2kU1EQ6N+san8VR/Qn26VVUpXPI8sGpBSEE++008ZPs1KbWU76AXV+bbztP4oqAAAAAElFTkSuQmCC'
        }, {
          label: 'Notice',
          url: '/person/notices',
          icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAbtJREFUWEftl1FOwzAMhn+rO0ThqUfZ7rBJvLEeYJM4wcYJkLYDrLwhbXdgN6G8oB5ixchpAlE6WOMyIaT1sbXjL/7txiH0eK4mq2dx56TOq6e7UrMUaZzExwYfWv+Sk3qkgVABhMEBZABUENEAfnBmGmFwKKlORAoVRBRAGLzazfYiQXrzkGkhOgN8F9zVkBaiBSALhYVJdbIBYAqOgRxJbXbeeg6DjIhNZ7iaOGbmF6sBSCerKQELq6O2MaL8ZCPVdl5QOl4PPeqoRfoaM9OSPG1LZsqlqmVhr6jgqj0qYCOHSNd0h1s7eC8AbLRlWla72X34k5HgrtqjAH7oDiu5wOELwGrif+wT/Fh3ON192VsArijBVGp3HmbKdNZhcOsyfBIgNtWx9heAzhm4nqw2DExjU+zbE1C8bee5/64zQDpeL4jYnfkqDgJKNYAqYgenzhnosJbK5ALwfzLgzQkqre0hV7hf8Of54I0AR88Cz1DasO9/YH9pw9bp2FUCtfAnHMMueJGxyZ+IzhU4qK2lm4gMgJ3bChC/nhuAADOOScy/nopHzb2gKQp3L2hdTH47I3JEvzM9ysj3AadRfpj6cjy8AAAAAElFTkSuQmCC'
        }],
        select: '/person/carts',
      }
    },
    mounted() {
      this.select = this.$route.path;
    },
    methods: {
      goDetail(url) {
        const that = this;
        that.select = url;
        if (that.$route.path == url) return;
        that.$router.push({ path: url });
      }
    }
  }
</script>

<style lang="less" scoped>
  .person-layout {
    width: 1300px;
    margin-left: auto;
    margin-right: auto;
    display: flex;

    .layout-left {
      width: 192px;
      height: 500px;
      margin-right: 8px;
      margin-top: 16px;
      margin-bottom: 20px;
      background-color: #ffffff;
      padding: 20px;

      .menu {
        .menu-title {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 36px;

          i {
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-bottom: -4px;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url(../assets/img/member-center.png);
          }
        }

        .menu-list {
          padding-left: 8px;

          .item-menu-title {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 8px;
          }

          .item-menu {
            padding-left: 4px;
            font-size: 16px;
            cursor: pointer;
            margin-bottom: 12px;
            color: #999999;

            i {
              display: inline-block;
              width: 18px;
              height: 18px;
              margin-bottom: -2px;
              background-repeat: no-repeat;
              background-size: contain;
            }
            &.select {
              color: #5199db;
            }
          }

          .line {
            margin-top: 17px;
            margin-bottom: 12px;
            width: 100%;
            height: 1px;
            border-bottom: 1px solid #999999;
          }
        }
      }
    }

    .layout-right {
      margin-bottom: 20px;
    }
  }
</style>