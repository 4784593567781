<template>
  <div class="layout" @click="clickLayout">
    <el-container>
      <el-header :height="'auto'">
        <div class="notice" v-if="noticeMessage" @click="goDetail">
          {{ noticeMessage }}
          <i class="el-icon-close close" @click="close"></i>
        </div>
        <div class="header">
          <div class="header-content">
            <div class="header-left" @click="goHome"></div>
            <div class="header-right">
              <div class="product-search">
                <!-- <el-input class="search-input" size="medium" v-model="keyword" placeholder="Search for products shop">
                  <el-button slot="append" icon="el-icon-search" @click="goCommodityListByKeyword"></el-button>
                </el-input> -->
              </div>
              <div class="item">
                <span @click.stop="showHelpCenter">Help Center</span>
                <i class="el-icon-arrow-down el-icon--right" @click.stop="showHelpCenter"></i>
                <div class="item-dropdown" @click.stop="" style="padding-top: 0;" v-if="helpCenterShow">
                  <div class="dropdown-content">
                    <div class="dropdown-list">
                      <div class="dropdown-item" @click="goHelpCenter">
                        <i class="help_center"></i>
                        Help Center
                      </div>
                    </div>
                    <div class="email-item">
                      <p>
                        <i class="email"></i>
                        Sending a message
                      </p>
                      <p>
                        <a style="color: #195592;" href="mailto:service@cnospcb.com">
                          <span>service@cnospcb.com</span>
                        </a>
                      </p>
                    </div>
                    <div class="phone-item">
                      <p>
                        <i class="phone"></i> Whatsapp/Service hotlines<br>

                      </p>
                      <div class="number">
                        <span>+86 <a href="https://wa.me/qr/GNA3BAVZCH4ZI1" target="_blank">16675334159</a></span>
                      </div>
                    </div>
                    <div class="time-item">
                      <i class="time"></i>
                      <span>{{ time }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="line"></div>
              <div class="item">English<i class="el-icon-arrow-down"></i></div>
              <div class="line"></div>
              <div class="item" @click="goCarts">
                <div :class="{ cart: cartConut, 'no-cart': !cartConut }">
                  <div class="cart-number" v-if="cartConut">{{ cartConut }}</div>
                </div>
                <div class="item-label">
                  <p>My Cart</p>
                </div>
              </div>
              <div class="line"></div>
              <div class="item" @click.stop="showAccount">
                <template v-if="!userDetail.userAvatar">
                  <img src="../assets/img/headIcon/head.png" alt="">
                </template>

                <template v-else>
                  <img :src="userDetail.avatar" alt="">
                </template>
                <i class="el-icon-arrow-down el-icon--right"></i>
                <div class="item-dropdown" v-if="accountShow">
                  <div class="dropdown-content">
                    <div class="login" v-if="!userEmail">
                      <div class="des">Welcome to Oceansmile</div>
                      <div class="button" @click="goLogin">Sign in</div>
                    </div>
                    <div class="register" v-if="!userEmail">
                      <div class="des">New Customer?</div>
                      <div class="button" @click="goLogin">Join Free</div>
                    </div>
                    <div class="user" v-if="userEmail" @click.stop="">
                      <p>Email: {{ userEmail }}</p>
                      <div class="button out" @click="signOut">Sign out</div>
                    </div>
                    <div class="dropdown-list">
                      <div class="dropdown-item" @click="goCarts">
                        <i class="icon cart-icon"></i>Shopping Cart
                      </div>
                      <div class="dropdown-item" @click="goOrderList">
                        <i class="icon orders-icon"></i> My Orders
                      </div>
                      <div class="dropdown-item" @click="goNotice">
                        <i class="icon progress-notice-icon"></i>Order Progress Notice&nbsp;
                        <i class="number" v-if="orderMsgNumber">{{ orderMsgNumber }}</i>
                      </div>
                      <div class="dropdown-item" @click="goCoupons">
                        <i class="icon coupons-icon"></i> My Coupons
                      </div>
                      <div class="dropdown-item" @click="goAddress">
                        <i class="icon address-icon"></i>Delivery Address
                      </div>
                      <div class="dropdown-item" @click="goProfile">
                        <i class="icon profile-icon"></i>My Profile
                      </div>
                      <div class="dropdown-item" @click="goLetter">
                        <i class="icon letter-icon"></i>Letter
                      </div>
                      <div class="dropdown-item" @click="goNotices">
                        <i class="icon notice-icon"></i>Notice
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="header-des">
                        <div class="des-item">
                            <i class="phone"></i>
                            <span>+86 16675334159</span>
                        </div>
                        <div class="des-item">
                            <a href="mailto:service@cnospcb.com">
                                <i class="email"></i>
                                <span>service@cnospcb.com</span>
                            </a>
                        </div>
                        <div class="des-item">
                            <i class="time"></i>
                            <span>Mon-Fri: 9:00-21:00 (UTC/GMT+8)</span>
                        </div>
                    </div> -->
        </div>
        <div class="header-menu">
          <div class="menu-content">
            <div class="menu-item" @click="goHome">Home</div>
            <div class="menu-item" @click="goPcbAssembly">PCB Assembly</div>
            <div class="menu-item">
              <el-dropdown>
                <span>
                  PCB Instant Quote<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <div @click="goQuote">PCB/PCBA Instant Quote</div>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <div @click="goStencil">Stencil Instant Quote</div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="menu-item">
              <el-dropdown placement="bottom-start">
                <span class="el-dropdown-link">
                  Product Shop<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown" style="padding: 0;">
                  <el-cascader-panel class="custom-cascader" :options="productCategoryList" ref="cascader"
                    :props="{expandTrigger: 'hover', emitPath: false, checkStrictly: true}" @change="handleChange">
                    <template slot-scope="{ node, data }">
                      <span>{{ data.label }}</span>
                    </template>
                  </el-cascader-panel>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="menu-item" @click="goBlogs">News&Blog</div>
            <div class="menu-item" @click="goCnc">CNC 3D Printing</div>
            <div class="menu-item" @click="goProduct">Product&Capacity</div>
            <div class="menu-item">
              <el-dropdown>
                <span>
                  About us<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <div @click="goAbout">About us</div>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <div @click="goContact">Contact us</div>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <div @click="goWhy">Why us</div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </el-header>
      <el-main>
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </el-main>
      <el-footer>
        <Footer />
      </el-footer>
    </el-container>
  </div>
</template>
<script>
  import { loginOut, orderMsgUnread, getCartNumber, getUserDetail, getNoticeTop, getProductCategoryOptionsList } from "@/api/api";
  import { t } from "@wangeditor/editor";
  import { EventBus } from '../utils/eventBus';
  import { Store } from "vuex";

  export default {
    components: {
      Footer: () => import("@/components/footer.vue")
    },
    data() {
      return {
        activeSubmenu: null,
        cartConut: 0,
        monut: 2000,
        helpCenterShow: false,
        accountShow: false,
        userEmail: '',
        orderMsgNumber: 0,
        userDetail: {},
        noticeMessage: '',
        noticeId: null,
        time: null,
        timeSetId: null,
        productCategoryList: [],
        keyword: ''
      }
    },
    //接收商城加入购物车之后 刷新的数据
    mounted() {
      const that = this;
      EventBus.$on('data-updated', (newData) => {
        that.refreshData(newData);
      });
      let email = localStorage.getItem("userEmail");
      if (email) {
        that.userEmail = email;
        that.getOrderMsgUnread();
        that.getUser();
      }
      that.getNotice();
      window.addEventListener('setItemEvent', that.getUserEmail.bind(that));
      that.timeSetId = setInterval(() => {
        let date = new Date();
        var year = date.getFullYear();
        var month = date.toLocaleDateString('en-US', { month: 'short' });
        var week = date.toLocaleDateString('en-US', { weekday: 'short' });
        var day = date.getDate().toString().padStart(2, '0');
        var hours = date.getHours().toString().padStart(2, '0');
        var minutes = date.getMinutes().toString().padStart(2, '0');
        var seconds = date.getSeconds().toString().padStart(2, '0');
        // date.getUTCDate       
        that.time = hours + ':' + minutes + ':' + seconds + ' ' + week + ' ' + month + ' ' + day + ' ' + year + '(GMT+8)';
        that.$forceUpdate();
      }, 1000);
      that.getProductCategory();
    },
    methods: {
      handleChange(value) {
        if (!value) return;
        if (value == -1) this.goCommodityList(null, null)
        else if (value == -2) this.goProductList();
        else this.goCommodityList(value, this.$refs.cascader.getCheckedNodes()[0].hasChildren);
        this.$refs.cascader.clearCheckedNodes();
      },
      refreshData(data) {
        this.cartConut = data;
      },
      getProductCategory() {
        const that = this;
        getProductCategoryOptionsList().then((res) => {
          if (res && res.code == 200 && res.data) {
            that.productCategoryList = that.formatChildren(res.data);
          } else {
            that.$message.error(res.msg);
          }
        }).catch((err) => {
          that.$message.error(err);
        });
      },
      formatChildren(data) {
        const that = this;
        return data.map((item) => {
          // 如果 children 是空数组，则设为 null
          if (Array.isArray(item.children) && item.children.length === 0) {
            item.children = null;
          } else if (Array.isArray(item.children)) {
            // 递归处理子节点
            item.children = that.formatChildren(item.children);
          }
          return item;
        });
      },
      goDetail() {
        const that = this;
        if (that.$route.path == '/person/notices/detail') return;
        that.$router.push({ "name": "noticeDetail", query: { id: that.noticeId } });
      },
      getNotice() {
        const that = this;
        getNoticeTop().then((res) => {
          if (res && res.code == 200 && res.data) {
            that.noticeMessage = res.data.title;
            that.noticeId = res.data.id;
          } else {
            that.$message.error(res.msg);
          }
        }).catch((err) => {
          that.$message.error(err);
        });
      },
      close() {
        const that = this;
        that.noticeMessage = "";
      },
      getUser() {
        const that = this;
        getUserDetail().then((res) => {
          if (res && res.code == 200) {
            that.userDetail = res.data;
            that.userDetail.avatar = require(`../assets/img/headIcon/${that.userDetail.userAvatar}`);
            that.$forceUpdate();
          } else {
            that.$message.error(res.msg);
          }
        }).catch((err) => {
          that.$message.error(err);
        })
      },
      signOut() {
        const that = this;
        loginOut().then((res) => {
          if (res.code == 200) {
            // localStorage.clear();
            that.userEmail = null;
            that.userDetail = {};
            that.$router.push({ 'name': 'login' });
            // location.reload();
            localStorage.removeItem("userEmail");
            sessionStorage.removeItem("token");
            // 在注销或401时
            that.$store.commit('SET_LOGGED_IN', false);
          } else {
            that.$message.error(res.msg);
          }
        }).catch((err) => {
          that.$message.error(err);
        })
      },
      showHelpCenter() {
        const that = this;
        that.helpCenterShow = !that.helpCenterShow;
      },
      goHelpCenter() {
        const that = this;
        if (that.$route.path == '/helpCenter') return;
        that.$router.push({ name: 'helpCenter' });
      },
      goHome() {
        const that = this;
        if (that.$route.path == '/home') return;
        that.$router.push({ name: 'home' });
      },
      goPcbAssembly() {
        const that = this;
        if (that.$route.path == '/assembly') return;
        that.$router.push({ name: 'assembly' });
      },
      goCommodityListByKeyword() {
        const that = this;
        that.$router.push({ name: 'commodityList', params: { 'keyword': that.keyword } });
      },
      goCommodityList(categoryId, isCategory) {
        const that = this;
        //防止重复点击
        if (that.$route.path == '/commodityList') return;
        that.$router.push({ name: 'commodityList', params: { 'categoryId': categoryId, 'isCategory': isCategory } });
      },
      goProductList() {
        const that = this;
        if (that.$route.path == '/productList') return;
        that.$router.push({ name: 'productList' });
      },
      goQuote() {
        const that = this;
        if (that.$route.path == '/order') return;
        that.$router.push({ name: 'order' });
      },
      goStencil() {
        const that = this;
        if (that.$route.path == '/stencil') return;
        that.$router.push({ name: 'stencil' });
      },
      goNews() {
        const that = this;
        if (that.$route.path == '/news') return;
        that.$router.push({ name: 'news' });
      },
      goBlogs() {
        const that = this;
        if (that.$route.path == '/forums') return;
        that.$router.push({ name: 'forums' });
      },
      goCnc() {
        const that = this;
        if (that.$route.path == '/cnc') return;
        that.$router.push({ name: 'cncView' });
      },
      goProduct() {
        const that = this;
        if (that.$route.path == '/product') return;
        that.$router.push({ name: 'product' });
      },
      goLogin() {
        const that = this;
        if (that.$route.path == '/login') return;
        that.$router.push({ name: 'login' });
      },
      goAbout() {
        const that = this;
        if (that.$route.path == '/aboutUs') return;
        that.$router.push({ name: 'aboutUs' });
      },
      goContact() {
        const that = this;
        if (that.$route.path == '/contactUs') return;
        that.$router.push({ name: 'contactUs' });
      },
      goWhy() {
        const that = this;
        if (that.$route.path == '/whyUs') return;
        that.$router.push({ name: 'whyUs' });
      },
      goCarts() {
        const that = this;
        if (that.$route.path == '/person/carts') return;
        that.$router.push({ name: 'carts' });
      },
      goOrderList() {
        const that = this;
        if (that.$route.path == '/person/orderList') return;
        that.$router.push({ name: 'orderList' });
      },
      goNotice() {
        const that = this;
        if (that.$route.path == '/person/notice') return;
        that.$router.push({ name: 'notice' });
      },
      goAddress() {
        const that = this;
        if (that.$route.path == '/person/address') return;
        that.$router.push({ name: 'address' });
      },
      goProfile() {
        const that = this;
        if (that.$route.path == '/person/profile') return;
        that.$router.push({ name: 'profile' });
      },
      goLetter() {
        const that = this;
        if (that.$route.path == '/person/letter') return;
        that.$router.push({ name: 'letter' });
      },
      goNotices() {
        const that = this;
        if (that.$route.path == '/person/notices') return;
        that.$router.push({ name: 'notices' });
      },
      goCoupons() {
        const that = this;
        if (that.$route.path == '/person/coupons') return;
        that.$router.push({ name: 'coupons' });
      },
      clickLayout() {
        const that = this;
        that.accountShow = false;
        that.helpCenterShow = false
      },
      showAccount() {
        const that = this;
        that.accountShow = !that.accountShow;
        if (that.userEmail) {
          that.getOrderMsgUnread();
        }
      },
      getUserEmail(event) {
        if (event.key == 'userEmail') {
          this.userEmail = event.newValue;
          if (this.userEmail) {
            this.getOrderMsgUnread();
            this.getUser();
          }
        }
      },
      getOrderMsgUnread() {
        const that = this;
        orderMsgUnread().then((res) => {
          if (res.code == 200) {
            that.orderMsgNumber = res.data;
          } else {
            that.$message.error(res.msg);
          }
        }).catch((err) => {
          that.$message.error(err);
        });
        that.$nextTick(() => {
          if (that.userEmail) {
            getCartNumber().then((res) => {
              if (res.code == 200) {
                that.cartConut = res.data.pcb + res.data.product;
              } else {
                that.$message.error(res.msg);
              }
            }).catch((err) => {
              that.$message.error(err);
            });
          }
        })
      },
      computeStyle(index) {
        const topPx = 73 - (index * 11); // 每行间距16px 初始 
        return {
          top: topPx + 'px', // 顶部间距
        };
      },
      showSubmenu(id) {
        this.activeSubmenu = id;
      },
      hideSubmenu() {
        this.activeSubmenu = null;
      },
      isSubmenuVisible(id) {
        return this.activeSubmenu === id;
      },
      keepSubmenuVisible() {
        // 这个方法保持当前的 activeSubmenu，不做任何操作即可
      },
    },
    watch: {
      $route(to, from) {
        if (to.path == '/login') {
          let token = sessionStorage.getItem('token');
          if (!token) {
            this.userEmail = null;
            this.userDetail = {};
            this.cartConut = 0;
            this.$forceUpdate();
          }
        }
      }
    },
    destroyed() {
      window.removeEventListener('setItemEvent', this.getUserEmail);
    }
  }
</script>
<style lang="less" scoped>
  .layout {
    min-width: 1200px;
  }

  .el-header,
  .el-footer {
    padding: 0;
  }

  .notice {
    background: #c9e0f9;
    line-height: 32px;
    text-align: center;
    position: relative;
    cursor: pointer;

    .close {
      position: absolute;
      top: 8px;
      right: 50px;
      cursor: pointer;
    }
  }

  .header {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #ffffff;

    .header-content {
      width: 1200px;
      padding: 8px 0px;
      display: flex;
      justify-content: space-around;

      .header-left {
        flex: 1;
        width: auto;
        height: 60px;
        background-image: url("@/assets/img/Logo.png");
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
      }

      .header-right {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .product-search {
          .search-input {
            width: 400px;
            padding: 0 70px;
          }
        }

        .item {
          padding-left: 24px;
          display: flex;
          align-items: center;
          cursor: pointer;
          white-space: nowrap;
          position: relative;

          .el-dropdown {
            font-size: 16px;
            color: #000000;
            cursor: pointer;
          }

          .item-label {
            text-align: left;
          }

          .cart,
          .no-cart {
            width: 35px;
            height: 35px;
            background-color: #5199dc;
            border-radius: 50%;
            margin-right: 6px;
            position: relative;
            background-repeat: no-repeat;
            background-size: 55%;
            background-position: center;

            .cart-number {
              position: absolute;
              top: -6px;
              right: 0px;
              width: 15px;
              height: 15px;
              border-radius: 50%;
              font-size: 12px;
              color: #ffffff;
              background-color: #195592;
              text-align: center;
            }
          }

          .cart {
            background-image: url("@/assets/img/shopping_cart1.jpg");
          }

          .no-cart {
            background-image: url("@/assets/img/shopping_cart2.jpg");
          }

          .item-dropdown {
            width: 250px;
            transform-origin: center top;
            z-index: 100;
            position: absolute;
            top: 24px;
            right: 0px;
            padding-top: 10px;
            margin: 5px 0;
            background-color: #ffffff;
            border: 1px solid #ebeef5;
            border-radius: 4px;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            text-align: left;
            cursor: auto;

            .dropdown-content {
              .login {
                margin-top: 10px;
                margin-left: 10px;
                margin-right: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid #e8e8e8;

                .des {
                  font-size: 14px;
                }

                .button {
                  margin-top: 8px;
                  padding: 8px;
                  border-radius: 5px;
                  background-color: #31af40;
                  text-align: center;
                  font-size: 14px;
                  font-weight: 800;
                  color: #ffffff;
                  cursor: pointer;
                }
              }

              .register {
                margin-top: 10px;
                margin-left: 10px;
                margin-right: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid #e8e8e8;

                .des {
                  font-size: 14px;
                }

                .button {
                  margin-top: 8px;
                  padding: 8px;
                  border-radius: 5px;
                  background-color: #195592;
                  text-align: center;
                  font-size: 14px;
                  font-weight: 800;
                  color: #ffffff;
                  cursor: pointer;
                }
              }

              .user {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 10px 10px;

                .user-icon {
                  width: 30px;
                  height: 30px;
                  margin-right: 8px;
                  background-color: #5199dc;
                  border-radius: 50%;
                  color: #ffffff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                .button {
                  margin-top: 8px;
                  padding: 6px 32px;
                  border-radius: 5px;
                  color: #ffffff;
                  cursor: pointer;
                  background-color: red;
                }
              }

              .email-item {
                margin-top: 10px;
                margin-left: 10px;
                margin-right: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid #e8e8e8;

                p {
                  display: flex;
                  align-items: center;
                  color: #5c5c5c;
                }

                .email {
                  display: inline-block;
                  width: 20px;
                  height: 20px;
                  background-repeat: no-repeat;
                  background-size: contain;
                  margin-right: 6px;
                  background-image: url("@/assets/img/h_2.png");
                }

                a {
                  margin-top: 12px;
                  padding-left: 27px;
                }
              }

              .time-item {
                margin-top: 10px;
                margin-left: 10px;
                margin-right: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid #e8e8e8;
                font-size: small;
                align-items: center;
                color: #195592;
              }

              .phone-item {
                margin-top: 10px;
                margin-left: 10px;
                margin-right: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid #e8e8e8;

                p {
                  display: flex;
                  align-items: center;
                  color: #5c5c5c;

                  .phone {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin-right: 6px;
                    background-image: url("@/assets/img/h_1.png");
                  }
                }

                .number {
                  margin-top: 12px;
                  padding-left: 27px;
                }
              }

              .dropdown-list {
                background-color: #f4f4f4;

                .dropdown-item {
                  padding: 10px 10px;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  font-weight: bold;

                  .help_center {
                    color: #195592;
                    background-image: url("@/assets/img/help_center.png");
                  }

                  .icon {
                    color: #dcdfe6;
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background-repeat: no-repeat;
                    background-size: contain;

                    &.cart-icon {
                      background-image: url(../assets/img/cart-icon.png);
                    }

                    &.orders-icon {
                      background-image: url(../assets/img/orders-icon.png);
                    }

                    &.progress-notice-icon {
                      background-image: url(../assets/img/progress-notice-icon.png);
                    }

                    &.coupons-icon {
                      background-image: url(../assets/img/coupons-icon.png);
                    }

                    &.address-icon {
                      background-image: url(../assets/img/address-icon.png);
                    }

                    &.profile-icon {
                      background-image: url(../assets/img/profile-icon.png);
                    }

                    &.letter-icon {
                      background-image: url(../assets/img/letter-icon.png);
                    }

                    &.notice-icon {
                      background-image: url(../assets/img/notice-icon.png);
                    }
                  }

                  &:hover {
                    background-color: #e8e8e8;
                  }

                  i {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin-right: 6px;
                    color: red;
                  }
                }
              }
            }
          }

          img {
            width: 40px;
            height: auto;
          }
        }

        .line {
          height: 18px;
          border-left: 1px solid #a2a2a2;
          margin-left: 24px;
        }
      }
    }

    .header-des {
      width: 1200px;
      padding: 4px 0px;
      display: flex;
      justify-content: flex-start;

      .des-item {
        display: flex;
        align-items: center;
        margin-right: 24px;

        i {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-size: contain;
          margin-right: 6px;

          &.phone {
            background-image: url("@/assets/img/h_1.png");
          }

          &.email {
            background-image: url("@/assets/img/h_2.png");
          }

          &.time {
            background-image: url("@/assets/img/h_3.png");
          }
        }

        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: #000000;
        }
      }
    }
  }

  .header-menu {
    background-color: #195592;
    display: flex;
    justify-content: center;

    .menu-content {
      width: 1300px;
      padding: 12px 0;
      display: flex;
      justify-content: space-around;

      .menu-item {
        color: #ffffff;
        font-weight: 800;
        cursor: pointer;
        position: relative;

        .el-dropdown {
          color: #ffffff !important;
          font-size: 16px;
        }
      }
    }
  }
</style>

<style scoped>
  /* 使用 v-deep 替换 /deep/ 以确保样式能够作用到子组件生成的 DOM */
  .custom-cascader ::v-deep .el-radio {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
    right: 0;
  }
  .custom-cascader ::v-deep .is-disabled {
    cursor: not-allowed;
  }
  .custom-cascader ::v-deep .el-radio__input {
    visibility: hidden;
  }
</style>

<style>
  .el-main {
    padding: 0px !important;
    background-color: #efefef;
    min-height: calc(100vh - 510px);
    overflow: initial;
  }

  .el-tabs--left .el-tabs__item.is-left {
    text-align: left !important;
  }

  .el-popper[x-placement^="right"] {
    margin-left: 21px !important;
    margin-top: -10px !important;
  }

  .el-popper .popper__arrow,
  .el-popper .popper__arrow::after {
    border-style: hidden !important;
  }

  .el-popover {
    background: #ffffff !important;
    border-radius: 0px !important;
    min-width: 50px !important;
    padding: 2px;
  }

  ul {
    padding-left: 0px;
    list-style-type: none;
  }

  li {
    margin: 10px 15px;
  }

  .category-name {
    cursor: pointer;
  }

  .category-name:hover {
    color: #195592 !important;
    text-decoration: underline;
  }

  .submenu-container.vertical.is-visible {
    display: block;
  }

  .submenu-container.vertical {
    width: 856px;
    height: auto;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    max-width: 856px;
    position: absolute;
    display: none;
    top: 50%;
    left: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 16px 0px rgba(39, 59, 53, 0.3),
      0px 0px 0px 0px rgba(0, 0, 0, 0.08);
    transform: translateY(-50%);
  }

  .submenu-container:before {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0);
    z-index: 0;
  }

  .submenu-container.vertical .last-level-menus {
    width: 100%;
    height: auto;
    padding: 12px 0.4672897196% 2.3364485981%;
    margin: 0;
    box-sizing: border-box;
    line-height: 0;
  }

  .submenu-container .last-level-menus .last-level-menus-title {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    padding-left: 30px;
    padding-top: 10px;
    cursor: pointer;
  }

  .submenu-container .last-level-menus .last-level-menus-title:hover {
    color: #195592 !important;
    text-decoration: underline;
  }

  .submenu-container.vertical .lastmenu-section {
    width: 25%;
    height: auto;
    padding: 2.380952381% 2.380952381% 0;
    margin: 0;
    box-sizing: border-box;
    grid-auto-flow: row;
  }

  .lastmenu-section-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .submenu-container .last-level-menus .lastmenu-section .lastmenu-section-title {
    width: 100%;
    height: auto;
    padding: 5px 0 0;
    margin: 6px 0 0;
    box-sizing: border-box;
    font-size: 13px;
    font-family: OpenSans-Bold, OpenSans;
    font-weight: 700;
    color: #464646;
    line-height: 18px;
    box-sizing: border-box;
  }

  .submenu-container .last-level-menus .lastmenu-section .lastmenu-sub-list {
    width: 100%;
    height: auto;
    padding: 5px 0 0;
    margin: 6px 0 0;
    border-top: 1px solid #195592;
  }

  .submenu-container
    .last-level-menus
    .lastmenu-section
    .lastmenu-sub-list
    .more-cate,
  .submenu-container
    .last-level-menus
    .lastmenu-section
    .lastmenu-sub-list
    .lastmenu-sub-item {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 14px;
    color: #565656;
    line-height: 22px;
  }
</style>