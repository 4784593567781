import Vue from 'vue'
import App from './App.vue'

import Vuex from 'vuex'
import router from './router'
import store from './store'

import 'element-ui/lib/theme-chalk/index.css';
import '@wangeditor/editor/dist/css/style.css';
import ElementUI from 'element-ui';
import dispatchEventStroage from '@/utils/watchLocalStorage';
import { message } from '@/utils/message';
import VueWechatTitle from 'vue-wechat-title';
import { i18nChangeLanguage } from '@wangeditor/editor';

// 切换语言 - 'en' 或者 'zh-CN'
i18nChangeLanguage('en');
Vue.use(Vuex);
Vue.use(ElementUI);
Vue.use(dispatchEventStroage);
Vue.use(VueWechatTitle);

Vue.config.productionTip = false

Vue.prototype.$message = message;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
